import React, { useState, useEffect } from "react";

import { Text, Flex, Box, Grid, Divider, formatPrice } from "flicket-ui";
import { useForm, Controller } from "react-hook-form";
import useSWR from "swr";

import {
  Layout,
  Select,
  FolderTabs,
  TabItem,
  ExportModalContent,
  ModalBase,
} from "~components";
import { usePOSReports } from "~graphql";
import { usePermissions, useSDK } from "~hooks";

import { Card, Chart } from "~components/pos";
import {
  OrderByDirection,
  PosEventsQuery,
  PosSessionsQuery,
  TerminalsQuery,
} from "~graphql/sdk";
import { withAuth, formatDate } from "~lib/helpers";
import { useScanReports } from "~features/reports/hooks/useScanReports";

const getEventOptions = (
  events: PosEventsQuery["events"]["edges"][number]["node"][]
) => [...(events?.map((v) => ({ value: v.id, label: v.title })) || [])];

const getTerminalOptions = (terminals: TerminalsQuery["poses"]) => [
  { label: "All Terminals", value: undefined },
  ...(terminals?.map((t) => ({ value: t.id, label: t.name })) || []),
];

const getSessionOptions = (operators: PosSessionsQuery["posSessions"]) => [
  { label: "All", value: undefined },
  ...(operators?.map((op) => ({
    value: op.id,
    label: `${formatDate(op.startedAt, "p")} - ${formatDate(
      op.endedAt,
      "p"
    )} | ${op?.operator?.fullName} | ${formatDate(op.startedAt, "dd-MM-yyyy")}`,
  })) || []),
];

export const getGraphData = (data) =>
  data?.map(({ identifier, items }) => {
    const scanned = items?.find((item) => item.name === "Scanned")?.value || 0;
    const expected =
      items?.find((item) => item.name === "Expected")?.value || 0;

    return {
      name: identifier,
      issued: expected,
      presented: scanned,
      toCome: Math.max(0, expected - scanned),
    };
  });

const Reporting = () => {
  const sdk = useSDK();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { hasPermissions, Permission, Error } = usePermissions();
  const { control, reset, watch } = useForm({
    defaultValues: {
      event: undefined,
      terminal: undefined,
      session: undefined,
    },
  });

  const formEvent = watch("event");
  const formTerminal = watch("terminal");
  const formSession = watch("session");

  const { data: events, error: eventsError } = useSWR(
    "events",
    async () =>
      sdk
        .posEvents({
          where: { pos: true },
          orderBy: { startDate: OrderByDirection.Asc },
        })
        .then((res) => res.events?.edges?.map(({ node }) => node)),
    {
      revalidateOnFocus: false,
    }
  );

  const eventId: string = formEvent?.value ?? formEvent;

  const {
    floatOfChange,
    posSales,
    posFinancials,
    sessions,
    sessionId,
    setTerminalId,
    setSessionId,
    setTab,
    swrTab,
  } = usePOSReports({ eventId });

  const { scanVisitors } = useScanReports({
    eventId,
    queries: {
      scanTicketTypes: false,
      scansOverTime: false,
    },
  });

  const { data: terminals } = useSWR(
    eventId ? ["terminals", eventId] : null,
    async (_: string, eventId: string) =>
      sdk.terminals({ eventId }).then((res) => res?.poses),
    {
      revalidateOnFocus: false,
    }
  );

  const eventOptions = [
    ...(!events && !eventsError
      ? [{ label: "Loading...", value: undefined }]
      : []),
    ...getEventOptions(events),
  ];
  const terminalOptions = getTerminalOptions(terminals);
  const sessionOptions = getSessionOptions(sessions?.data);

  useEffect(() => {
    if (events?.length > 0) {
      reset({
        event: events[0].id,
        terminal: undefined,
      });
    }
  }, [events?.length]);

  useEffect(() => {
    setTerminalId(formTerminal?.value ?? formTerminal);
    setSessionId(undefined);
  }, [formTerminal]);

  useEffect(() => {
    setSessionId(formSession?.value ?? formSession);
  }, [formSession]);

  useEffect(() => {
    if (activeIndex === 0 && swrTab !== "sales") {
      setTab("sales");
    }

    if (activeIndex === 1 && swrTab !== "sales") {
      setTab("finances");
    }
  }, [activeIndex, swrTab]);

  const tabs: TabItem[] = [
    {
      name: "Overview",
      key: "overview",
      content: (
        <Box>
          <Text variant="heavy.XL" color="N800">
            Sales Overview
          </Text>
          <Divider mt={2} mb={3} />
          <Grid rowSize={4} rowGap={3} columnGap={3}>
            <Card
              key="sales-sold-at-door"
              icon="my-tickets"
              id="sold-at-door"
              stat={posSales?.data?.soldAtTheDoor?.toLocaleString()}
              title="Tickets sold at the door"
            />
            <Card
              icon="my-tickets"
              key="sales-orders"
              id="orders"
              stat={posSales?.data?.orders.toLocaleString()}
              title="Orders"
            />
            <Card
              icon="my-tickets"
              key="sales-average"
              id="average"
              stat={posSales?.data?.average.toLocaleString()}
              title="Average"
            />
            <Card
              icon="my-tickets"
              key="sales-per-hour"
              id="per-hour"
              stat={posSales?.data?.perHour.toLocaleString()}
              title="Tickets an hour"
            />
            <Card
              icon="my-tickets"
              key="sales-sold-online"
              id="sold-online"
              stat={posSales?.data?.soldOnline.toLocaleString()}
              title="Tickets sold online"
            />
            <Card
              icon="my-tickets"
              key="sales-percentage"
              id="percentage"
              stat={posSales?.data?.scannedPercentage.toLocaleString()}
              title="Percentage scanned"
            />
          </Grid>
          <Flex>
            <Text mt={6} variant="heavy.XL" color="N800">
              Scanning breakdown
            </Text>
          </Flex>
          <Divider mt={2} mb={3} />
          <Chart data={getGraphData(scanVisitors.data?.scanVisitors || [])} />
        </Box>
      ),
    },
    {
      name: "Finance",
      key: "finance",
      content: (
        <>
          <Box>
            <Text variant="heavy.XL" color="N800">
              Sales Overview
            </Text>
            <Divider mt={2} mb={3} />
            <Grid rowSize={4} rowGap={3} columnGap={3}>
              <Card
                icon="my-tickets"
                id="sold-at-door"
                stat={posSales?.data?.soldAtTheDoor?.toLocaleString()}
                title="Tickets sold at the door"
              />
              <Card
                icon="my-tickets"
                id="orders"
                stat={posSales?.data?.orders.toLocaleString()}
                title="Orders"
              />
              <Card
                icon="my-tickets"
                id="average"
                stat={posSales?.data?.average.toLocaleString()}
                title="Average"
              />
              <Card
                icon="my-tickets"
                id="per-hour"
                stat={posSales?.data?.perHour.toLocaleString()}
                title="Tickets an hour"
              />
            </Grid>
            <Text mt={6} variant="heavy.XL" color="N800">
              Financial overview
            </Text>
            <Divider mt={2} mb={3} />
            <Grid rowSize={4} rowGap={3} columnGap={3}>
              {posFinancials?.data?.map(
                (item) =>
                  item.value > 0 && (
                    <Card
                      icon="graph"
                      bg="S100"
                      color="S300"
                      title={item.name}
                      id={item.name}
                      key={item.name}
                      stat={formatPrice(item.value)}
                    />
                  )
              )}
            </Grid>
          </Box>
          {!!formTerminal && (
            <Box>
              <Text mt={6} variant="heavy.XL" color="N800">
                Float of change
              </Text>
              <Divider mt={2} mb={3} />
              <Grid rowSize={4} rowGap={3} columnGap={3}>
                {floatOfChange?.data
                  ?.filter(({ name }) => name !== "Net sales")
                  .map(({ name, value }) => (
                    <Card
                      icon="graph"
                      bg="S100"
                      color="S300"
                      title={name}
                      id={`${formTerminal}-${name}`}
                      key={`${formTerminal}-${name}`}
                      stat={formatPrice(value)}
                    />
                  ))}
              </Grid>
            </Box>
          )}
        </>
      ),
    },
  ];

  return hasPermissions(Permission.PosCreate) ? (
    <Layout title="Reporting" description="Setup Point of Sale Reporting">
      <Flex alignItems="center" justifyContent="flex-start" pb={3} width={1}>
        <Box minW="308px" mr={2}>
          <Controller
            name="event"
            as={Select}
            control={control}
            options={eventOptions}
            defaultValue={eventOptions?.[0]?.value}
          />
        </Box>
        {activeIndex === 1 && (
          <>
            <Flex flex="1">
              <Box mr={2} minW="180px">
                <Controller
                  name="terminal"
                  as={Select}
                  control={control}
                  options={terminalOptions}
                  defaultValue={terminalOptions?.[0]?.value}
                />
              </Box>
              <Box mr={2} minW="256px">
                <Controller
                  name="session"
                  as={Select}
                  control={control}
                  options={sessionOptions}
                  defaultValue={sessionOptions?.[0]?.value}
                />
              </Box>
              {formSession && (
                <Flex alignItems="flex-end">
                  <Text fontSize={3} color="N500" mr={2}>
                    Opened up:{" "}
                    {formatDate(
                      sessions?.data?.find(({ id }) => id === sessionId)
                        ?.startedAt,
                      "dd-MM-yyy p"
                    )}{" "}
                    - Closed up:{" "}
                    {formatDate(
                      sessions?.data?.find(({ id }) => id === sessionId)
                        ?.endedAt,
                      "dd-MM-yyy p"
                    )}
                  </Text>
                </Flex>
              )}
            </Flex>
          </>
        )}
      </Flex>
      <FolderTabs
        activeIndex={activeIndex}
        onTabChange={setActiveIndex}
        items={tabs}
      />

      <ModalBase isOpen={isOpen} close={() => setIsOpen(false)}>
        <ExportModalContent close={() => setIsOpen(false)} />
      </ModalBase>
    </Layout>
  ) : (
    <Error statusCode={401} />
  );
};

export default withAuth(Reporting);
